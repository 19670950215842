import React from "react";
import Post from "../components/Post";
import PostList from "../components/PostList";
import { hiringNewCreator } from "./Recruit";

export default function Home() {
  return (
    <PostList id="posts">
      <Post title="About">
        <p>
          ArsFortuna は、Minecraft: JavaEdition の本格 RPG サーバです。
          <br />
          現在制作中です。
          <br />
          本ウェブサイトは開発中につきご利用の端末の画面サイズによってはレイアウトが崩れる場合があります。ご了承ください。
        </p>
      </Post>

      {hiringNewCreator && (
        <Post title="Recruitment">
          <p>
            当サーバでは新規運営を募集しています。詳しくは以下のページをご覧ください。
            <br />
            <a href="/recruit">Recruit | ArsFortuna</a>
          </p>
        </Post>
      )}

      <Post title="Twitter">
        <p>
          公式Twitter <a href="https://twitter.com/ArsFortunaRPG">@ArsFortunaRPG</a> 及びハッシュタグ{" "}
          <a href="https://twitter.com/search?q=%23ArsFortuna">#ArsFortuna</a> でも不定期に進捗報告をしています。
          <br />
        </p>
      </Post>
    </PostList>
  );
}
