import React from "react";
import HiddenDivider from "../components/HiddenDivider";
import Post, { SubTitle } from "../components/Post";
import PostList from "../components/PostList";
import { operators, Role } from "../constants";

const width = window.parent.screen.width;

const rolesA = [Role.OWNER, Role.DIRECTOR, Role.BUILDER];
const rolesB = [Role.DESIGNER, Role.DEVELOPER, Role.ARRANGER];
const supporters = [
  {
    name: "kako0951",
    detail: "総合的に御支援いただきました。",
  },
  {
    name: "tutifrutjp",
    detail: "地形編集・建築にご協力いただきました。",
  },
  {
    name: "Kuniyon",
    detail: "素材アイテムのテクスチャにご協力いただきました。",
  },
  {
    name: "suola0921",
    detail: "ワールドの区画分けにご協力いただきました。",
  },
  {
    name: "flat_music_sign",
    detail: "エフェクトアイコン・GUI・素材アイテムのテクスチャにご協力いただきました。",
  },
];

const renderMain = () => (
  <Post title="Creation">
    <p>
      当サーバの運営システムについての説明です。ゲームシステムについては<a href="/guide">Guide</a>をご覧ください。
      <div className="ui link list">
        <a className="item" href="#operator">
          Operator
        </a>
        <a className="item" href="#contact">
          Contact
        </a>
      </div>
    </p>
  </Post>
);

const renderGratitude = () => (
  <>
    <p>
      ArsFortuna は、これまで多くの御支援の元に運営して参りました。
      <br />
      以下の方々には特に御深慮いただきましたので、この場にて感謝の意を表します。
    </p>
    <div className={`ui ${width < 700 ? "one" : width < 980 ? "two" : "three"} cards`}>
      {supporters.map((sp) => (
        <div className="card">
          <div className="content">
            <h3 className="ui header">{sp.name}</h3>
            <div className="description">{sp.detail}</div>
          </div>
        </div>
      ))}
    </div>
  </>
);

const renderContact = () => (
  <Post id="contact" title="Contact">
    ご連絡の際は、ArsFortuna公式Twitter <a href="https://twitter.com/ArsFortunaRPG">@ArsFortunaRPG</a>{" "}
    からお願いします。
    <br />
    運営への応募以外での運営者個人への連絡は当サーバのサポート外となりますのでご注意ください。
  </Post>
);

const rolesRenderer = {
  mobile: () => (
    <div className="ui middle aligned divided list" v-if="width < 550">
      {[...rolesA, ...rolesB].map((r) => (
        <div className="item">
          <h3 className="header">{r.name}</h3>
          {r.about}
        </div>
      ))}
    </div>
  ),
  desktop: () => (
    <div className="ui internally celled grid">
      <div className="row">
        {rolesA.map((r) => (
          <div className="five wide column">
            <span className="ui medium header">{r.name}</span>
            <p>{r.about}</p>
          </div>
        ))}
      </div>

      <div className="row">
        {rolesB.map((r) => (
          <div className="five wide column">
            <span className="ui medium header">{r.name}</span>
            <p>{r.about}</p>
          </div>
        ))}
      </div>
    </div>
  ),
};

const operatorsRenderer = {
  mobile: () => (
    <div className="ui two cards">
      {operators.map((op) => (
        <div className="card">
          <div className="content">
            <img className="ui medium image" src={`https://cravatar.eu/helmavatar/${op.name}/80.png`} alt="skin" />

            {width < 400 ? <span>{op.name}</span> : <h3>{op.name}</h3>}

            <div className="description">
              <div className="ui labels">
                <div className={`ui circular label ${op.permissionLevel.color}`}>{op.permissionLevel.name}</div>
                {op.twitter === null ? null : (
                  <a v-if="" href={`https://twitter.com/${op.twitter}`}>
                    <i className="black twitter icon"></i>
                  </a>
                )}
              </div>

              <div className="ui labels">
                {op.roles.map((r) => (
                  <div className="ui basic label">{r.name}</div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  ),
  desktop: () => (
    <div className={`ui ${width < 980 ? "two" : "three"} cards`}>
      {operators.map((op) => (
        <div className="card">
          <div className="content">
            <h3 className="ui header">
              <img className="ui medium image" src={`https://cravatar.eu/helmavatar/${op.name}/80.png`} alt="skin" />

              <div className="content">
                {op.name}
                <a target="_blank" rel="noreferrer" href={`https://twitter.com/${op.twitter}`}>
                  <i className="black twitter icon"></i>
                </a>
              </div>
            </h3>

            <div className="description">
              <div className="ui labels">
                <div className={`ui circular label ${op.permissionLevel.color}`}>{op.permissionLevel.name}</div>
                {op.roles.map((r) => (
                  <div className="ui basic label">{r.name}</div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  ),
};

export default function Home() {
  return (
    <PostList id="posts">
      {renderMain()}

      <Post id="operator" title="Operator">
        <p>
          当サーバの運営に関わるプレイヤーの一覧です。
          <br />
          運営者は、権限レベルと役職を持ちます。
          <br />
          権限レベルは、運営者に与えられている権限を示します。IからIVの間で設定され、数値が大きいほど上位の権限を有します。
          <br />
          役職は、運営者の担当部門を示します。
        </p>

        <HiddenDivider />
        {width < 550 ? rolesRenderer.mobile() : rolesRenderer.desktop()}
        <HiddenDivider />
        {width < 580 ? operatorsRenderer.mobile() : operatorsRenderer.desktop()}
        <SubTitle title="Gratitude" />
        {renderGratitude()}
      </Post>

      {renderContact()}
    </PostList>
  );
}
