import React from "react";
import Post from "../components/Post";
import PostList from "../components/PostList";

export default function Game() {
  return (
    <PostList id="posts">
      <Post title="Game">
        <p>現在作成中です。</p>
      </Post>
    </PostList>
  );
}

/*
export default function Game() {
  return (
    <PostList id="posts">
      <Post title="Guide">
        <p>
          当サーバのゲームシステムについての説明です。運営システムについては<a href="/creation">Creation</a>
          をご覧ください。
        </p>
      </Post>

      <Post title="魔法">
        <p></p>
      </Post>

      <Post title="作業台">
        <p>作業台を使うと、武器や杖に魔法やスフィアを付加できます。 魔法種は魔法の元となるアイテムで、</p>
      </Post>

      <Post title="村人">
        <p>ワールド上には対話ができたり、クエストを持ちかけてくる村人がいます。</p>
      </Post>
    </PostList>
  );
}
*/
