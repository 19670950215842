import React from "react";
import styled from "styled-components";
import logo from "../img/logo_with_icon.png";
import { pages } from "../Router";

const isActivePage = (pagePath) => window.location.pathname === pagePath;

export default class Menu extends React.Component {
  render() {
    return window.parent.screen.width < 600 ? this.renderMobile() : this.renderDesktop();
  }

  renderMobile() {
    return (
      <div className="ui inverted menu">
        <a href="/">
          <img className="ui large image" src={logo} alt="logo"></img>
        </a>

        <MobileSelectMenu
          defaultValue={Object.values(pages).find((p) => isActivePage(p.path))?.path ?? "_disabled"}
          onChange={(event) => (window.location.href = event.target.value)}
        >
          <option value="_disabled" disabled></option>
          {Object.values(pages).map((page, i) => (
            <option value={page.path} key={i}>
              {page.name}
            </option>
          ))}
        </MobileSelectMenu>
      </div>
    );
  }

  renderDesktop() {
    return (
      <div className="ui inverted large top menu">
        <a href="/">
          <img className="ui medium image" src={logo} alt="logo"></img>
        </a>

        {Object.values(pages).map((page, i) => (
          <a className={isActivePage(page.path) ? ["active item"] : ["item"]} href={page.path} key={i}>
            {page.name}
          </a>
        ))}
      </div>
    );
  }
}

const MobileSelectMenu = styled.select`
  position: relative;
  cursor: pointer;
  appearance: none;
  width: 100%;
  text-align: center;
  background: #323232;
  color: aliceblue;
  border: 4px;
  border-color: aliceblue;
  padding: 20px;
`;
