import React from "react";
import styled from "styled-components";

export default class Copyright extends React.Component {
  render() {
    return (
      <div className="ui center aligned container">
        <Text className="item" href="/">
          (c) ArsFortuna
        </Text>
      </div>
    );
  }
}

const Text = styled.a`
  color: black;
`;
