import React from "react";

export default function Post({ title, children }) {
  return (
    <article className="ui raised left aligned container segment">
      {title ? <h1 className="ui dividing header">{title}</h1> : null}
      {children}
    </article>
  );
}

export function SubTitle({ title }) {
  return <h2 class="ui dividing header">{title}</h2>;
}
