import React from "react";
import styled from "styled-components";
import Copyright from "./components/Copyright";
import Menu from "./components/Menu";
import background from "./img/background.png";
import Router from "./Router";

export default class App extends React.Component {
  render() {
    return (
      <Container>
        <Menu />
        <Router />
        <Copyright />
      </Container>
    );
  }
}

const Container = styled.div`
  background: #000 url(${background}) no-repeat fixed bottom;
  background-size: cover;
  min-height: 100%;
  position: relative;
`;
