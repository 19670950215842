import React from "react";

export default class Error404 extends React.Component {
  render() {
    return (
      <div class="ui container">
        <h1 class="ui top attached header">404 Not Found</h1>
        <p class="ui attached segment">The server has not found anything matching the Request-URI.</p>
      </div>
    );
  }
}
