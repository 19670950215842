import React from "react";
import PostList from "../components/PostList";
import Post from "../components/Post";

const prohibitedActs = [
  "サーバに不正に負荷をかける行為。",
  "意図的なラグ、バグの使用行為。",
  "許可されていないMod、ツールの導入/使用行為。",
  "名誉棄損や広告、連続投稿などにより不当にチャット欄を使用する行為。",
  "児童ポルノやわいせつにあたる発言或いはスキン、ユーザネームの使用行為。",
  "RMT(リアルマネートレード)やバランスの取れていない取引行為。",
  "ゲームの攻略を目的としてログアウトする行為。",
  "その他、運営側が極めて不当と判断する行為。",
];

const allowedMods = [
  "OptiFine",
  "MinecraftForge",
  "IntelliInput",
  "CocoaInput",
  "InputFix",
  "UnderlineFix",
  "FastCraft",
  "BetterFPS",
  "InventoryCenterer",
];

const allowedTools = ["MCPatcher Extended HD", "MCJPISS 2", "McDjit", "Btcon for マインクラフト"];

export default function Rule() {
  return (
    <PostList id="posts">
      <Post title="Rule">
        <p>
          当サーバでは以下の行為を禁止しています。
          <br />
          これらに該当する行為が確認できた場合、運営陣が処罰を行ないます。
          <br />
          処罰ではその程度によってポイントを加算し、合計4ポイントに達すると接続禁止になります。
          <br />
          処罰時から30日以内であれば、<a href="/system#contact">System#Contact</a> より異議申し立てが可能です。
          <br />
          違反者を見つけた場合、<a href="/system#contact">System#Contact</a> より報告をお願いします。
        </p>
      </Post>

      <Post title="禁止行為">
        <div className="ui bulleted list">
          {prohibitedActs.map((a, i) => (
            <div className="item" key={i}>
              {a}
            </div>
          ))}
        </div>
      </Post>

      <Post title="許可Mod/ツール">
        <p>正規の配布元から入手したものを使用してください。</p>

        <div className="ui grid">
          <div className="eight wide column">
            <h2>Mod</h2>
            <div className="ui bulleted list">
              {allowedMods.map((m, i) => (
                <div className="item" key={i}>
                  {m}
                </div>
              ))}
            </div>
          </div>

          <div className="eight wide column">
            <h2>ツール</h2>
            <div className="ui bulleted list">
              {allowedTools.map((t, i) => (
                <div className="item" key={i}>
                  {t}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Post>
    </PostList>
  );
}
