import React from "react";
import Post, { SubTitle } from "../components/Post";
import PostList from "../components/PostList";

const width = window.parent.screen.width;

const Role = {
  BUILDER: {
    hiring: false,
    name: "Builder",
    about: "地形・建築物の作成/建築を担当します。WorldEdit等、建築補助プラグインが使用できると好ましいです。",
  },
  DESIGNER_BOTH: {
    hiring: false,
    name: "Designer (2D/3D)",
    about:
      "アイテム・エンティティのリソースパックの作成を担当します。2D, 3D のいずれか或いは両方を選択できます。2D では主に GUI や素材アイテム、3D では武器アイテムやエンティティのモデルを扱います。",
  },
  DESIGNER_3D: {
    hiring: false,
    name: "Designer (3D)",
    about: "アイテム・エンティティのモデル作成を担当します。使用するツールの制限はありません。",
  },
  DESIGNER_2D: {
    hiring: false,
    name: "Designer (2D)",
    about: "GUI や素材アイテムのテクスチャ作成を担当します。使用するツールの制限はありません。",
  },
  DEVELOPER: {
    hiring: false,
    name: "Developer",
    about:
      "Java 及び Kotlin を用いてゲームシステムの開発を担当します。RPG システム部分につきまして、Skript や MythicMobs 等の配布プラグインは使用しません。",
  },
  ARRANGER: {
    hiring: false,
    name: "Arranger",
    about: "音響設定を担当します。",
  },
};

export const hiringNewCreator = Object.values(Role).find((r) => r.hiring === true);

export default function Recruit() {
  return (
    <PostList id="posts">
      {!hiringNewCreator ? (
        <Post title="Rec">
          <p>現在 ArsFortuna では新規運営者を募集しておりません。</p>
        </Post>
      ) : (
        <>
          <Post title="Recruit">
            <p>
              ArsFortuna では国内最高峰のクォリティを目指し、これまで制作を続けて参りました。
              <br />
              よりよい運営を行うため、新規運営者を募集します。
            </p>
            <p>
              ご不明点などありましたら <a href="https://twitter.com/natureIni">Twitter @natureIni</a>{" "}
              までご連絡ください。
            </p>
          </Post>
          <Post id="operator" title="募集要項">
            <p>
              制作に参加する上で情報共有のために <a href="https://discordapp.com/">Discord</a>,{" "}
              <a href="https://www.google.com/">Google</a> のアカウントを有している必要があります。
              また、責任を持って制作に参加できる方を求めます。
            </p>

            <SubTitle title="募集役職" />

            {width < 550 ? (
              <div class="ui middle aligned divided list">
                {Object.values(Role)
                  .filter((r) => r.hiring)
                  .map((r) => (
                    <div class="item">
                      <h3>{r.name}</h3>
                      {r.about}
                    </div>
                  ))}
              </div>
            ) : (
              <div class="ui grid">
                <div class="row">
                  {Object.values(Role)
                    .filter((r) => r.hiring)
                    .map((r) => (
                      <div class="sixteen wide column">
                        <span class="ui medium header">{r.name}</span>
                        <p>{r.about}</p>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </Post>
          <Post title="応募方法">
            <p>
              以下のフォームより必要事項を記入の上送信してください。
              <br />
              確認後入力された Discord アカウントに連絡します。
              <br />
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSeZJ7GHyJIYBj5tvqSvMx308UZmC7Bo1YBcWJBP-2aK7ZcG7g/viewform">
                応募フォーム
              </a>
            </p>
          </Post>
        </>
      )}
    </PostList>
  );
}
