export const PermissionLevel = {
  IV: {
    name: "IV",
    color: "red",
  },
  III: {
    name: "III",
    color: "violet",
  },
  II: {
    name: "II",
    color: "blue",
  },
  I: {
    name: "I",
    color: "green",
  },
};

export const Role = {
  OWNER: {
    name: "Owner",
    about: "総合管理",
  },
  DIRECTOR: {
    name: "Director",
    about: "運営指揮",
  },
  BUILDER: {
    name: "Builder",
    about: "地形/建築物創造",
  },
  DESIGNER: {
    name: "Designer",
    about: "リソースパック作成",
  },
  DEVELOPER: {
    name: "Developer",
    about: "ゲームシステム実装",
  },
  ARRANGER: {
    name: "Arranger",
    about: "音響設定、その他",
  },
};

export const operators = [
  {
    name: "natureIni",
    permissionLevel: PermissionLevel.IV,
    roles: [Role.OWNER, Role.DEVELOPER],
    twitter: "natureIni",
  },
  {
    name: "ExbomuUltra",
    permissionLevel: PermissionLevel.IV,
    roles: [Role.DIRECTOR, Role.DEVELOPER],
    twitter: "ExbomuUltra_mc",
  },
  {
    name: "kinoko721",
    permissionLevel: PermissionLevel.III,
    roles: [Role.BUILDER],
    twitter: "Motto_Suki_Pio",
  },
  {
    name: "you_oscar",
    permissionLevel: PermissionLevel.II,
    roles: [Role.DESIGNER],
    twitter: "ZeROs_ch",
  },
  /*{
        name: 'okinawafeir',
        permissionLevel: PermissionLevel.II,
        roles: [Role.DESIGNER, Role.DEVELOPER],
        twitter: null
    },*/
  {
    name: "SORYU501",
    permissionLevel: PermissionLevel.II,
    roles: [Role.ARRANGER],
    twitter: "AEGIS51",
  },
  {
    name: "modazii",
    permissionLevel: PermissionLevel.II,
    roles: [Role.ARRANGER],
    twitter: "modazii",
  },
  {
    name: "Husk_Boy",
    permissionLevel: PermissionLevel.II,
    roles: [Role.BUILDER, Role.DEVELOPER],
    twitter: "LuteSkyf",
  },
  {
    name: "_mudou_",
    permissionLevel: PermissionLevel.II,
    roles: [Role.BUILDER],
    twitter: "u_mudo",
  },
  {
    name: "TMTN_amero_kun",
    permissionLevel: PermissionLevel.II,
    roles: [Role.DESIGNER],
    twitter: "TMt_amerokun",
  },
  {
    name: "arupaaaaka",
    permissionLevel: PermissionLevel.II,
    roles: [Role.DESIGNER],
    twitter: "arupaka_mine",
  },
  {
    name: "hiroyoo963",
    permissionLevel: PermissionLevel.I,
    roles: [Role.DEVELOPER],
    twitter: null,
  },
];

export const cities = {
  aurora_city: "アウローラ",
  kitrea_city: "キトレア",
  fortschritt_garrison: "フォルトシュリット駐屯地",
};
