import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Creation from "./pages/Creation";
import Game from "./pages/Game";
import Home from "./pages/Home";
import Recruit from "./pages/Recruit";
import Rule from "./pages/Rule";
import Error404 from "./pages/error/404";

export const pages = {
  Home: {
    name: "Home",
    path: "/",
    component: Home,
  },
  Game: {
    name: "Game",
    path: "/game",
    component: Game,
  },
  Rule: {
    name: "Rule",
    path: "/rule",
    component: Rule,
  },
  Creation: {
    name: "Creation",
    path: "/creation",
    component: Creation,
  },
  Recruit: {
    name: "Recruit",
    path: "/recruit",
    component: Recruit,
  },
};

const errors = {
  404: {
    status: 404,
    name: "404 Not Found",
    component: Error404,
  },
};

const getCurrentPage = () => Object.values(pages).find((p) => window.location.pathname === p.path) ?? errors[404];

export default class App extends React.Component {
  componentDidMount() {
    document.title = getCurrentPage().name + " | ArsFortuna";
  }

  render() {
    return (
      <Router>
        <Switch>
          {Object.values(pages).map((v, i) => (
            <Route exact path={v.path} component={v.component} key={i}></Route>
          ))}
          {Object.values(errors).map((v, i) => (
            <Route status={v.status} component={v.component} key={i}></Route>
          ))}
        </Switch>
      </Router>
    );
  }
}
